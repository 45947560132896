<template>
	<td class="case has-text-centered" :class="{'indisponible' : reservation, 'courante' : courante}">
		<template v-if="!reservation">
			<button class="button is-small" :class="{'is-loading' : loading }" @click="reserver()">
				<span class="icon is-small">
					<i class="fas fa-plus-circle"></i>
				</span>
			</button>
		</template>
		<template v-else>

			<button @mouseover="afficherTooltip=true"  @mouseout="afficherTooltip=false" class="button reservation" :class="{'is-loading' : loading }" :style="style" @click="annuler()" >
				<span v-html="contenu"></span>
			</button>
			<transition name="fade">
				<span v-if="afficherTooltip" v-html="tooltip"></span>
			</transition>
		</template>
	</td>
</template>
<script>
	export default {
		props:['jour','heure','endroit', 'user'],
		data() {
			return {
				loading:false,
				afficherTooltip:false
			}
		},
		methods : {
			annuler() {
				if(this.isAdmin || this.reservation.user_id == this.$store.state.user.id) {
					let confirmed = sessionStorage.getItem('confirmed');
					if(confirmed || confirm('Annuler cette réservation ?\n(Cette demande de confirmation ne sera affiché qu\'une seule fois)')) {
						sessionStorage.setItem('confirmed',true);
						this.loading=true;
						this.api.post('annuler-reservation',{id:this.reservation.id}).then(response => {
							this.$bus.$emit('charger-endroit');
							setTimeout(() => {
								this.loading=false;
							},100);
						});	

					}
				}
			},
			reserver(){
					this.loading=true;
					let params = {
						endroit_id:this.endroit.id,
						jour:this.jour.date,
						debut:this.heure.debut,
						fin:this.heure.fin,
						user_id:this.user
					};
					console.log(params);
					this.api.post('reservation',params).then(response => {
						if(response.data.reservation.message) {
							alert(response.data.reservation.message);
						} else {
							this.$bus.$emit('charger-endroit');
						}
						setTimeout(() => {
							this.loading=false;
						},100);
					});				
			}
		},
		mounted() {

		},
		computed : {
			tooltip() {
				let objet='';
				if(this.reservation.objet)  {
					objet = '<b>'+this.reservation.objet.toUpperCase()+'</b>\n';
				}
				return (objet+'Reservé par '+this.nom+'\nle '+this.reservation.creation).replaceAll('\n','<br>');
			},
			contenu() {
				if(this.reservation.objet) {
					let ret = this.reservation.objet.toUpperCase();

					if(this.reservation.objet.length > 20) {
						ret = ret.substr(0,20)+'&hellip;';
					}
					return ret;
				} else {
					return this.nom;
				}
			},
			nom() {
				let tmp = this.reservation.user.split(' ');
				let initiale = tmp[0].substr(0,1).toUpperCase();
				tmp.shift();
				return (initiale+'. '+tmp.join(' ')).toUpperCase();
			},
			style() {
				if(this.reservation) {
					return {
						background:this.reservation.couleur1,
						color:this.reservation.couleur2
					}
				}
			},
			reservation() {
				for (let reservation of this.endroit.reservations) {
					if(reservation.jour == this.jour.date) {
						if(reservation.debut <= this.heure.debut && reservation.fin > this.heure.debut) {
							return reservation;
						}
					}
				}				
			},
			courante() {
				if(this.endroit.date.jour.date == this.jour.date) {
					if(this.heureCourante() > this.heure.debut) {
						if(this.heureCourante() < this.heure.fin) {
							return true;
						}
					}
				} else {
					return false;
				}

			}
		}
	}
</script>
<style lang="scss">
.case {
	position: relative;
	& > span {
		display: none;
		@media screen and (min-width: 1024px) {
			display: block;
			position: absolute;
			z-index: 1000;
			bottom: 100%;
			left: 0;
			width: 110%;
			transform: translateX(-5%);
			background: rgba(0,0,0,0.8);
			color:white;
			padding:1em;
		}
	}
	&.courante button {
		border:1px solid red;
	}
	font-size: 10px;
	button {
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		border:none;
		text-align: center;
		overflow: hidden;
		max-width: 100%;
	}
	&.indisponible {
		button {
			font-size: 11px;
		}
	}
	&:not(.indisponible) {
		button {
			background: transparent;
			span {
				opacity: 0.2;
				transition: .2s ease;
			}
		}
		&:hover{
			button span {
				opacity: 1;

			}
		}
	}

}


</style>