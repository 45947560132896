<template>
  <div class="container" v-if="endroit">
    <div class="columns is-centered">
      <div class="column">
        <div class="columns">
          <div class="column">
            <h1 class="title">{{endroit.libelle}}</h1>
            <h1 class="subtitle">{{endroit.description}}</h1>

            <div v-if="endroit.equipe">
              Réservations restreinte aux membres de l'équipe
              <b>{{endroit.equipe}}</b>
            </div>
            <template v-if="endroit.ferme">
              <div class="column is-half is-offset-one-quarter">
                <article class="message is-danger">
                  <div class="message-header">
                    <p>Salle fermée à la réservation</p>
                  </div>
                  <div class="message-body">{{endroit.message_ferme}}</div>
                </article>
              </div>
            </template>
            <template v-else>
              <div v-if="user">
                <p>
                  Réservation en tant que
                  <b>{{user.nom}}</b>
                  <button @click="$bus.$emit('no-user')" class="button is-text is-small">Annuler</button>
                </p>
              </div>
              <Pagination :endroit="endroit" :loading="loading" />

              <div class="table-container">
                <table class="table is-fullwidth is-bordered">
                  <tr>
                    <th></th>
                    <template v-for="jour in endroit.date.semaine">
                      <th class="has-text-centered">
                        <div>{{jour.nom}}</div>
                        <small>{{jour.Date}}</small>
                        <div class="buttons are-small has-addons is-centered">
                          <button
                            v-for="creneau in endroit.creneaux"
                            @click="reserverCreneau(jour.date,creneau)"
                            class="button"
                            :data-tooltip="'Réserver '+creneau.libelle"
                          >{{creneau.icone}}</button>
                        </div>
                      </th>
                    </template>
                  </tr>
                  <tr v-for="heure in endroit.heures" :data-heure="heure.debut">
                    <th>
                      <time>{{heure.debut}}</time>
                      <time>{{heure.fin}}</time>
                    </th>
                    <Case
                      v-for="jour in endroit.date.semaine"
                      :jour="jour"
                      :heure="heure"
                      :endroit="endroit"
                      :user="user ? user.id : false"
                    />
                  </tr>
                </table>
              </div>

              <template v-if="isAdmin">
                <div class="columns" v-if="selectUser">
                  <div class="column is-half is-offset-one-quarter has-text-centered">
                    <UserSearch />
                  </div>
                </div>

                <template v-else>
                  <center>
                    <a
                      @click="selectUser=true"
                    >🤷‍♀️ Faire une réservation pour un autre utilisateur</a>
                  </center>
                </template>
                <template v-if="endroit.ecran">
                  <template v-if="voirEcran">
                    <span class="ecran">
                      <img :src="endroit.ecran" />
                    </span>
                    <center>
                      <div>
                        Ip de l'écran:
                        <b>{{endroit.ip}}</b>
                      </div>
                      <div>
                        Mise à jour:
                        <b>{{endroit.modification}}</b>
                      </div>
                    </center>
                  </template>
                  <template v-else>
                    <center>
                      <a @click="voirEcran=true">📺 Voir l'écran de cette salle</a>
                    </center>
                  </template>
                </template>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserSearch from "@bit/sopress.composants.user-search";
import Pagination from "@/components/Pagination.vue";
import Case from "@/components/Case.vue";
export default {
  components: { Pagination, Case, UserSearch },
  data() {
    return {
      voirEcran: false,
      selectUser: false,
      endroit: false,
      user: false,
      loading: false,
    };
  },
  watch: {
    $route(to, from) {
      this.chargerEndroit();
    },
  },
  mounted() {
    this.$bus.$on("no-user", () => {
      this.$router.push({ name: "Endroit", params: this.$router.params });
    });

    this.$bus.$on("user-search-click", (user) => {
      this.$router.push({
        name: "Endroit",
        params: this.$router.params,
        query: { user_id: user.id },
      });
      //				this.user = user.id;
    });

    this.chargerEndroit();
    // setInterval(this.chargerEndroit,30000);

    this.$bus.$on("charger-endroit", this.chargerEndroit);
  },
  methods: {
    reserverCreneau(jour, creneau) {
      // if (this.jePeuxReserver(this.endroit)) {
      if (this.creneauLibre(jour, creneau)) {
        if (
          confirm("Voulez-vous vraiment réserver " + creneau.libelle + " ?")
        ) {
          this.loading = true;
          let objet = "";
          if (this.endroit.type == "montage") {
            objet = prompt("Objet de la réservation\n(Facultatif)");
          }
          let params = {
            endroit_id: this.endroit.id,
            objet: objet ? objet : "",
            jour: jour,
            debut: creneau.debut,
            fin: creneau.fin,
          };
          this.api.post("reservation", params).then((response) => {
            if (response.data.reservation.message) {
              alert(response.data.reservation.message);
            } else {
              this.$bus.$emit("charger-endroit");
            }
            this.loadingEnd();
          });
        }
      } else {
        alert(
          "Impossible de réserver " +
            creneau.libelle +
            " car des réservations sont déjà présentes sur ce créneau."
        );
      }
      // }
    },
    chargerEndroit() {
      this.user = false;
      if (this.$route.query.user_id) {
        this.getUser(this.$route.query.user_id).then((user) => {
          this.user = user;
        });
      }
      this.loading = true;
      if (document.activeElement) {
        document.activeElement.blur();
      }
      let semaine = false;
      if (this.$route.params.semaine) {
        semaine = this.$route.params.semaine;
      }
      this.api
        .get("endroits", {
          params: {clause: { id: this.$route.params.id, semaine: semaine }},
        })
        .then((response) => {
          this.endroit = response.data.endroits[0];
        })
        .finally(() => {
          this.loadingEnd();
        });
    },
    creneauLibre(jour, creneau) {
      let reservations = [];
      for (let reservation of this.endroit.reservations) {
        if (reservation.user_id != this.$store.state.user.id) {
          if (reservation.jour == jour) {
            if (
              reservation.debut >= creneau.debut &&
              reservation.debut <= creneau.fin
            ) {
              return false;
              // reservations.push(reservation);
            }
          }
        }
      }
      // console.log(JSON.parse(JSON.stringify(reservations)))
      return true;
    },
    loadingEnd() {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    },
  },
};
</script>
<style lang="scss">
th {
  font-size: 12px;
}
table {
  position: relative;
}
tr[data-heure*="12:"],
tr[data-heure*="13:"],
tr[data-heure*="19:"],
tr[data-heure*="20:"] {
  td,
  th {
    background: #eee !important;
  }
}
th {
  position: relative;
  height: 3em;
  width: 2.5em;
  time:nth-child(1) {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
  }
  time:nth-child(2) {
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
    font-size: 10px;
    font-weight: 100;
  }
}
.ecran {
  display: block;
  text-align: center;
  img {
    border: 1px solid #ccc;
    max-width: 100%;
  }
}
</style>