<template>
  <div class="container">      

        <h2 class="title has-text-weight-bold has-text-centered mt-6 mb-5">
          Digicodes
        </h2>

      <div class="columns is-multiline is-centered">   
        <div v-for="item in items" class="column is-4 card my-3 mx-3">
          <div class="card-content p-2">
            <div class="media">
              <div class="media-left">
                <figure class="image is-30x30">
                  <i
                    v-bind:class="`${item.icon} fa-2x`"
                  ></i>
                </figure>
              </div>

              <div class="media-content ml-3 p-2">
                <p class="title is-5 has-text-grey-light">{{ item.porte }}</p>
                <p class="subtitle is-6 has-text-weight-bold has-text-grey">
                  {{ item.numero }}
                </p>
              </div>
            </div>
          </div>
        </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          porte: "Grille pour entrer",
          numero: "7359A",
          icon: "fad fa-sign-in",
        },
        {
          porte: "Grille pour sortir",
          numero: "A8137",
          icon: "fad fa-sign-out",
        },
        {
          porte: "Porte du SAS",
          numero: "5309*",
          icon: "fad fa-door-closed",
        },
        {
          porte: "Boite à clés salles de montage",
          numero: "1606",
          icon: "fad fa-key",
        },
      ],
    };
  },
};
</script>
