<template>
	<nav class="pagination is-right" role="navigation" aria-label="pagination">
		<template v-if="endroit.date.jour.semaine != endroit.date.pagination.semaine">
			<router-link :to="{name:'Endroit',params:{id:endroit.id,slug:endroit.slug}}" class="pagination-previous">Cette Semaine</router-link>
		</template>
		<ul class="pagination-list">
			<li>
				<router-link :to="{name:'EndroitSemaine',params:{id:endroit.id,slug:endroit.slug,semaine:endroit.date.pagination.semainePrev},query:this.$route.query}"
				class="pagination-link">
					&laquo;
				</router-link>
			</li>
			<li>
				<button class="button pagination-link is-current" :class="{'is-loading' : loading}">
					Semaine {{endroit.date.pagination.semaine}}
				</button>
			</li>
			<li>
				<router-link :to="{name:'EndroitSemaine',params:{id:endroit.id,slug:endroit.slug,semaine:endroit.date.pagination.semaineNext},query:this.$route.query}"
				class="pagination-link">
					&raquo;
				</router-link>
			</li>

		</ul>
	</nav>

</template>
<script>
	export default {
		data() {
			return {
			}
		},
		props: ['endroit', 'loading']
	}
</script>